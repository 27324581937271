<template>

  <div class="ssDiv">
    <div class="search">

      <div class="nd">
        <span>型号名称:</span>
        <a-input style="text-align: right;width: 159px;" placeholder="请输入型号" v-model:value="dataValue.getPrice.input.modelName"/>
      </div>
      <div class="nd">
        <span>排单目标价:</span>
        <a-input style="text-align: right;width: 159px;" v-inputPrice placeholder="排单目标价" v-model:value="dataValue.getPrice.input.targetPriceString"/>
      </div>
      <div class="nd">
        <span>批号年限:</span>
        <a-select class="sSelect" v-model:value="dataValue.getPrice.input.batchYear">
          <a-select-option :value="0">选择批号年限</a-select-option>
          <a-select-option :value="1">小于1年</a-select-option>
          <a-select-option :value="2">大于1年 并 小于 2年</a-select-option>
          <a-select-option :value="3">大于2年 并 小于 3年</a-select-option>
          <a-select-option :value="5">大于3年 并 小于 5年</a-select-option>
          <a-select-option :value="6">大于5年</a-select-option>
        </a-select>
      </div>
      <div class="nd">
        <span>输出的货币:</span>
        <a-select class="sSelect" v-model:value="dataValue.getPrice.input.moneyTypeId">
          <a-select-option :value="5">输出为 - 人民币</a-select-option>
          <a-select-option :value="3">输出为 - 美元</a-select-option>
        </a-select>
      </div>
      <div class="nd">
        <span>贸易商子类型:</span>
        <a-select class="sSelect" v-model:value="dataValue.getPrice.input.companyType2Id">
          <a-select-option :value="1">贸易商子类 - 外资</a-select-option>
          <a-select-option :value="2">贸易商子类 - 现货</a-select-option>
          <a-select-option :value="3">贸易商子类 - 渠道</a-select-option>
          <a-select-option :value="4">贸易商子类 - 统货</a-select-option>
        </a-select>
      </div>
    </div>
    <a-button style="float: left;" @click="allEvent.set.goOpen">设置</a-button>
    <a-button type="primary" style="float: right;" @click="allEvent.setPrice.getPrice">计算检索</a-button>
  </div>
  <div class="show">
    <a-spin class="loadingClass" v-if="dataValue.getPrice.loadingSet" tip="正在加载中"></a-spin>
    <div v-if="!dataValue.getPrice.loadingSet && dataValue.searchRun">

      <div class="vB">
        <div class="v1">{{dataValue.getPrice.output.data['modelName']}}</div>
        <div class="v2"><div>模拟成本报价</div> :<span class="p1">{{dataValue.getPrice.output.data['moneyTypeKey']}} {{dataValue.getPrice.output.data['simulationCostPrice'].toFixed(5)}}</span></div>
        <div class="v2"><div>模拟原厂协议价</div> :<span class="p1">{{dataValue.getPrice.output.data['moneyTypeKey']}} {{dataValue.getPrice.output.data['simulationFactoryPrice'].toFixed(5)}}</span></div>
        <div class="v2"><div>模拟市场波动价</div> :<span class="p1">{{dataValue.getPrice.output.data['moneyTypeKey']}} {{dataValue.getPrice.output.data['simulationMarketPrice'].toFixed(5)}}</span></div>
      </div>
      <div class="vC">
        <div class="row">
          <div class="v3"><b>工厂库存</b>: <span class="p">{{dataValue.getPrice.output.data['moneyTypeKey']}} {{dataValue.getPrice.output.data['gckcPrice'].toFixed(5)}}</span></div>
          <div class="v4">(&nbsp;&nbsp;{{dataValue.getPrice.output.data['gckcRemark']}}&nbsp;&nbsp;)</div>
        </div>
        <div class="row">
          <div class="w">
            <div class="v3"><b>工厂销售</b>: <span class="p">{{dataValue.getPrice.output.data['moneyTypeKey']}} {{dataValue.getPrice.output.data['gcxhPrice'].toFixed(5)}}</span></div>
            <div class="v4">(&nbsp;&nbsp;{{dataValue.getPrice.output.data['gcxhRemark']}}&nbsp;&nbsp;)</div>
          </div>
          <div class="w">
            <div class="v3"><b>工厂排单</b>: <span class="p">{{dataValue.getPrice.output.data['moneyTypeKey']}} {{dataValue.getPrice.output.data['gcpdPrice'].toFixed(5)}}</span></div>
            <div class="v4">(&nbsp;&nbsp;{{dataValue.getPrice.output.data['gcpdRemark']}}&nbsp;&nbsp;)</div>
          </div>
        </div>
        <div class="row">
          <div class="v3"><b>贸&nbsp;&nbsp;易&nbsp;&nbsp;商</b>: <span class="p">{{dataValue.getPrice.output.data['moneyTypeKey']}} {{dataValue.getPrice.output.data['mysPrice'].toFixed(5)}}</span></div>
          <div class="v4">(&nbsp;&nbsp;{{dataValue.getPrice.output.data['mysRemark']}}&nbsp;&nbsp;)</div>
        </div>
      </div>
    </div>
  </div>

  <a-drawer
      class="aDrawer"
      title="价格C参数设置"
      width="700"
      :visible="dataValue.set.visible"
      :maskClosable="true"
      :destroyOnClose="true"
      placement="right"
      @close="allEvent.set.goClose"
  >
    <priceCSet @goClose="allEvent.set.goClose" />
  </a-drawer>

</template>

<script>
import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";
import priceCSet from "@/view/tools/price_c_set";

export default {
  name : "price_c",
  emits : [ 'setMenuKey' ],
  components:{ priceCSet },
  setup(props, { emit }) {
    emit('setMenuKey', 'gj.jgcxc');


    let dataValue = reactive({

      searchRun : false,
      loadingSet : true,
      loadingSetText : "正在加载中 ...",
      getPrice : {
        loadingSet : false,
        input : {
          modelName : "5M570ZT144C5N",
          targetPriceString : "0.00",
          targetPrice : 0.00,
          batchYear : 0,
          companyType2Id : 1,
          moneyTypeId : 5
        },
        output : {
          data : {}
        }

      },
      set : {
        visible : false
      }
    });



    let allEvent = {


      set :  {

        goClose : ()=>{
          dataValue.set.visible = false;
        },

        goOpen :()=>{
          dataValue.set.visible = true;
        }
      },

      setPrice : {

        getPrice : () => {

          if (dataValue.getPrice.input.modelName.trim() === "") {
            message.error( "请输入型号名称")
          } else {

            dataValue.getPrice.loadingSet = true;
            dataValue.searchRun = true;
            dataValue.getPrice.input.targetPrice = parseFloat(dataValue.getPrice.input.targetPriceString);

            // console.log(dataValue.getPrice.input);

            httpRequest.post('/modelobj/price.c', dataValue.getPrice.input).then((res) => {

              if (res.code !== 200) {
                message.error(res.message, 5);
              } else {
                dataValue.getPrice.output.data = res.data;
              }

              dataValue.getPrice.loadingSet = false;

            })
          }

        }
      },


      init : () => {

      }
    }

    allEvent.init();

    return {
      dataValue,
      allEvent
    }
  }
}
</script>

<style scoped>



.ssDiv { top: 66px; left: 0; height: 100%; width: 300px; clear: both; float: left;
  border-right: 1px solid #cccccc; padding: 20px; }
.ssDiv .search { width: 100%; float: left; margin-bottom: 20px; }
.ssDiv .search input { width: 180px; float: left; text-transform: uppercase; }
.ssDiv .search button { float: right; }

.ssDiv .search .nd{ clear: both; float: left;line-height: 34px;margin-top: 10px; }
.ssDiv .search .nd > span{ float: left;width: 100px; }
.ssDiv .search .nd .sSelect { width: 159px; float: left; }

.show { background-color: #e6f7ff; position: fixed; left: 300px; width: calc(100% - 300px);
  height: 100%; padding: 0 0 0 30px; }
.loadingClass { width: 100%; margin-top: 50px; }

.show .vB{ font-size:18px; margin-bottom: 10px; float: left; width: 330px;
  margin-top: 30px; }
.show .vB .v1{ font-size:18px; margin-bottom: 10px; float: left; font-weight: bold; }
.show .vB .v2{ font-size:14px; margin: 3px 0 20px 20px; float: left; clear: both; }
.show .vB .v2 div{ width: 110px; text-align:justify; text-align-last:justify; justify-content:space-between; display:inline-block; font-weight: bold;  }
.show .vB .v2 .p1{ color: #225DDC; margin-left: 10px; width: 120px; display: block;float: right; text-align: right; }
.show .vC{ font-size:14px; float: left; margin-top: 17px; }
.show .vC .row{ clear: both; float: left; }
.show .vC .row .v3{ font-size:14px; margin: 3px 0 5px 20px; clear: both; }
.show .vC .row .w{ width: 380px; float: left;  }
.show .vC .row .v4{ font-size:14px; margin: 3px 0 20px 20px; color: #666666; clear: both; }
b{ font-weight: bold; }
.p{ color: #225DDC; margin-left: 10px; }

</style>